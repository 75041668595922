import moment from 'moment';

export function mapDuration(duration: number): string {
    var diff = moment.duration(duration);
    return (diff.days() > 0 ? String(diff.days() + "(d)") : "")  +
        String(diff.hours()).padStart(2,'0') + ':' +
        String(diff.minutes()).padStart(2,'0') + ':' +
        String(diff.seconds()).padStart(2,'0') + '.' +
        String(diff.milliseconds()).padStart(3,'0');
}
